import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/public/dc/banner1-fish.png");
;
import(/* webpackMode: "eager" */ "/app/public/dc/category/category1.svg");
;
import(/* webpackMode: "eager" */ "/app/public/dc/category/category10.svg");
;
import(/* webpackMode: "eager" */ "/app/public/dc/category/category2.svg");
;
import(/* webpackMode: "eager" */ "/app/public/dc/category/category3.svg");
;
import(/* webpackMode: "eager" */ "/app/public/dc/category/category4.svg");
;
import(/* webpackMode: "eager" */ "/app/public/dc/category/category5.svg");
;
import(/* webpackMode: "eager" */ "/app/public/dc/category/category6.svg");
;
import(/* webpackMode: "eager" */ "/app/public/dc/category/category7.svg");
;
import(/* webpackMode: "eager" */ "/app/public/dc/category/category8.svg");
;
import(/* webpackMode: "eager" */ "/app/public/dc/category/category9.svg");
;
import(/* webpackMode: "eager" */ "/app/public/dc/homepage-footer1.svg");
;
import(/* webpackMode: "eager" */ "/app/public/dc/homepage-footer2.svg");
;
import(/* webpackMode: "eager" */ "/app/public/dc/homepage-footer3.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/jobs/JobList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/SearchForm/SearchForm.tsx");
