"use client";
import { NoopType } from "@/ts/types";
import { useEffect } from "react";


export const useMount = (callback: NoopType) => {
  useEffect(() => {
    callback();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
