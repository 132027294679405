import { JobListElementProps } from '@/ts/interfaces';

export const jobCaseHelper = ({ provider, e }: { provider: string; e: any }) => {
  if (provider === 'jobCase' && window.l5_trk) {
    window.l5_trk(e.currentTarget);
  }
};

export const locationFormatter = ({ city, state }: any) => {
  return [city && city !== 'null' ? city : '', state ?? ''].filter(Boolean).join(', ') || 'Remote';
};

export const deduplicateJobs = (jobs: Array<JobListElementProps>) => {
  const seen = new Set();
  const cleanString = (str: string) =>
    str
      .trim()
      .toLowerCase()
      .replace(/[^a-z]/g, '');

  let deduplicated: Array<JobListElementProps> = [];

  for (const job of jobs) {
    const cleanedCompany = cleanString(job.company); // Clean the company name

    if (!seen.has(cleanedCompany)) {
      seen.add(cleanedCompany); // Mark this company as seen
      deduplicated.push(job); // Add the job to the deduplicated list
    } else {
      const uniqJob = deduplicated.find((el) => el.company === job.company);
      if (
        (uniqJob && uniqJob.cpc && job?.cpc && job?.cpc > uniqJob?.cpc) ||
        (job.cpc && !uniqJob?.cpc)
      ) {
        deduplicated = deduplicated.filter(
          (el) => cleanString(el.company) !== cleanString(job.company),
        );
        deduplicated.push(job);
      }
    }
  }

  return deduplicated;
};

export const getJobDataFromPathname = (pathname: string, rest: Record<string, string>) => {
  if (rest.title) {
    const cityStateRegExp = /^(.+?)\s*(?:\(\s*(.+?)\s*\)|,\s*(.+?))\s*,?$/;
    const matches = cityStateRegExp.exec(rest.location) || [];

    return {
      title: rest.title,
      company: rest.company,
      city: matches[1] || '',
      state: matches[2] || matches[3] || '',
    };
  }
  const jobPath = pathname.split('/').pop() ?? '';
  const jobParts = jobPath.split('-');
  jobParts.pop();

  const atIndex = jobParts.indexOf('at');
  const title = jobParts.splice(0, atIndex).join(' ');

  if (atIndex >= 0) {
    jobParts.shift();
  }

  const inIndex = jobParts.indexOf('in');
  const company = jobParts.splice(0, inIndex).join(' ');

  if (inIndex >= 0) {
    jobParts.shift();
  }

  const state = jobParts.pop();
  const city = jobParts.join(' ');

  return {
    city: city ? city.charAt(0).toUpperCase() + city.slice(1) : '',
    state: state ? state.toUpperCase() : '',
    title: title ? title.charAt(0).toUpperCase() + title.slice(1) : '',
    company: company ? company.charAt(0).toUpperCase() + company.slice(1) : '',
  };
};

export const sortJobsByCpc = (arr: Array<JobListElementProps>, chunkSize = 5) => {
  let sortedArray: Array<JobListElementProps> = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    sortedArray = [
      ...sortedArray,
      ...arr.slice(i, i + chunkSize).sort((a, b) => {
        if (!a.cpc || !b.cpc) {
          return 0;
        }
        if (b.cpc !== a.cpc) {
          return b.cpc - a.cpc;
        } else {
          return Math.random() - 0.5;
        }
      }),
    ];
  }
  return sortedArray;
};
