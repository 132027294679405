'use client';

import { Dispatch, SetStateAction } from 'react';
import { cn } from '@/helpers/common';
import IconCaret from '@public/icons/caret-icon.svg';

import Dropdown from './Dropdown';
import { TDropdownOptionElement } from '@/ts/types';

const SearchDropdown = ({
  classNames,
  currentOption,
  options,
}: {
  currentOption: string;
  options?: TDropdownOptionElement[];
  classNames: {
    mainButtonClassName?: string;
    mainButtonActiveClassName?: string;
    mainButtonTextClassName?: string;
    rootCn?: string;
    listCn?: string;
    elementCn?: string;
    activeElementCn?: string;
    elementIconCn?: string;
    dividerCn?: string;
  };
}) => {
  return (
    <Dropdown
      rootCn={classNames?.rootCn}
      listCn={classNames?.listCn}
      elementCn={classNames?.elementCn}
      activeElementCn={classNames?.activeElementCn}
      dividerCn={classNames?.dividerCn}
      options={options}
    >
      {({
        setIsOpen,
        isOpen,
      }: {
        setIsOpen: Dispatch<SetStateAction<boolean>>;
        isOpen: boolean;
      }) => (
        <button
          onClick={() => {
            setIsOpen((prev) => !prev);
          }}
          type="button"
          className={cn(
            'flex items-center justify-between relative default-transition',
            classNames?.mainButtonClassName,
            isOpen ? classNames?.mainButtonActiveClassName : '',
          )}
        >
          <span className={classNames?.mainButtonTextClassName}>{currentOption}</span>
          <IconCaret
            className={cn(
              'w-2.5 h-2 ml-2 flex-shrink-0 transition-all duration-300 [&>path]:transition-all [&>path]:duration-300',
              isOpen ? 'rotate-180' : 'rotate-0',
            )}
          />
        </button>
      )}
    </Dropdown>
  );
};

export default SearchDropdown;
