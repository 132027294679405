'use client';

import React from 'react';
import PortfolioIcon from '../../../public/svgs/portfolio.svg';

import { cn } from '@/helpers/common';
import { locationFormatter } from '@/helpers/miscellaneous-helpers';
import { TJSJobType } from '@/ts/types';

interface JobListProps {
  jobs: TJSJobType[];
  listTitle: string;
  listElementClassName?: {
    listTitle?: string;
    listElement?: string;
    listElementDiv?: string;
    listElementCompany?: string;
    listElementDescription?: string;
    listElementTitle?: string;
  };
  kimbleGroup?: boolean;
  stubComponent?: React.ReactNode;
  isDebug?: boolean;
  withDescription?: boolean;
  mainClassName?: string;
  listClassName?: string;
}

const JobList: React.FC<JobListProps> = ({
  jobs,
  listTitle,
  listElementClassName,
  mainClassName,
  listClassName,
  kimbleGroup = false,
  stubComponent,
  isDebug = false,
  withDescription = false,
}) => {
  return (
    <section className={mainClassName} id="jobs-list">
      {listTitle && (
        <h2
          className={cn(
            'text-lg font-bold capitalize text-main-text-color',
            listElementClassName?.listTitle,
          )}
        >
          {listTitle}
        </h2>
      )}
      {jobs.length ? (
        <div className={cn('grid grid-cols-1 lg:grid-cols-2 gap-4 pb-10', listClassName)}>
          {jobs.map((job) => (
            <JobItem
              key={job.id}
              title={job.title}
              company={job.company}
              jobPageUrl={job.jobPageUrl}
              description={job.description}
              location={locationFormatter(job.location)}
              elementClassName={listElementClassName}
              kimbleGroup={kimbleGroup}
              id={job.id}
              isDebug={isDebug}
              withDescription={withDescription}
              jobUrls={isDebug ? job.jobUrls : []}
            />
          ))}
        </div>
      ) : stubComponent ? (
        stubComponent
      ) : (
        <></>
      )}
    </section>
  );
};

interface JobItemProps {
  id: string;
  title: string;
  company: string;
  jobPageUrl: string;
  location: string;
  description?: string;
  elementClassName?: {
    listElement?: string;
    listElementDiv?: string;
    listElementCompany?: string;
    listElementDescription?: string;
    listElementTitle?: string;
  };
  kimbleGroup?: boolean;
  isDebug: boolean;
  withDescription: boolean;
  jobUrls?: Array<{ source: string; cpc?: string }>;
}

const JobItem: React.FC<JobItemProps> = ({
  id,
  title,
  company,
  jobPageUrl,
  location,
  description,
  elementClassName,
  kimbleGroup,
  isDebug,
  withDescription,
  jobUrls,
}) => {
  return (
    <div className={cn('statistics text-sm', elementClassName?.listElement)} id={id}>
      <div className="flex">
        {kimbleGroup && <PortfolioIcon className="w-[15px] h-[14px] mt-1 mr-5 flex-shrink-0" />}
        <div className={elementClassName?.listElementDiv}>
          <a
            className={cn(
              'block mb-[3px] text-list-title-color no-underline hover:underline visited:text-visited-color',
              elementClassName?.listElementTitle,
            )}
            href={jobPageUrl}
            rel="noopener"
          >
            {title}
          </a>
          <div>
            <span
              className={cn(
                'font-bold text-list-company-color',
                elementClassName?.listElementCompany,
              )}
            >
              {company}
            </span>
            <span className="text-list-loc-color">
              {kimbleGroup && ' - '}
              {location}
            </span>
          </div>
        </div>
      </div>
      {withDescription && description && (
        <div className={cn('line-clamp-2', elementClassName?.listElementDescription)}>
          {description}
        </div>
      )}
      {isDebug && (
        <div className="w-fit text-main-text-color p-1 border-2 border-main-border-color break-all mt-2">
          <b>job searcher</b>
          {jobUrls &&
            jobUrls.map((urlObj, index) => (
              <div key={urlObj.source ? index + urlObj.source : index}>
                {urlObj.source} - {urlObj.cpc}
              </div>
            ))}
          <div className="border-t border-main-border-color mt-1">id - {id}</div>
        </div>
      )}
    </div>
  );
};

export default JobList;
