'use client';
import IconsSprites from '@/components/IconsSprites';
import { cn, formatLocationCaseText, makeQueryString } from '@/helpers/common';

import { useMount } from '@/hooks/useMount';
import { useRouter } from 'next/navigation';
import React, { FormEvent, useMemo, useState } from 'react';
import SearchField from './SearchField';
import { jobSearcherSuggestHandler } from '@/helpers/suggest-helper';
import { ROUTES } from '@/constants/routes/default';
import SearchDropdown from '../Dropdowns/SearchDropdown';

export interface SearchFormProps {
  fromHomePage?: boolean;
  q?: string;
  l?: string;
  baseUrl?: string;
  headerText?: string;
  buttonText?: string;
  queryParameters?: { [key: string]: string | undefined };
  dropdownOptions?: Array<{ title: string; link: string }>;
  dropdownClassNames?: {
    mainButtonClassName?: string;
    mainButtonActiveClassName?: string;
    mainButtonTextClassName?: string;
    rootCn?: string;
    listCn?: string;
    elementCn?: string;
    activeElementCn?: string;
    elementIconCn?: string;
    dividerCn?: string;
  };
  placeholders?: { location: string; keyword: string };
  formClassName?: {
    main?: string;
    listTitle?: string;
    button?: string;
    btnArrow?: string;
  };
  searchFieldClassName?: {
    input?: string;
    suggestions?: string;
    suggestionElement?: string;
    suggestionElementActive?: string;
    keywordInputClassName?: string;
    locationInputClassName?: string;
  };
  scrollElementId?: string;
}

const SearchForm: React.FC<SearchFormProps> = ({
  fromHomePage,
  q,
  l,
  baseUrl = ROUTES.jobs,
  queryParameters,
  formClassName,
  headerText,
  buttonText = 'Search',
  placeholders = { location: 'Location', keyword: 'Find your perfect job' },
  searchFieldClassName,
  scrollElementId,
  dropdownOptions,
  dropdownClassNames,
}) => {
  const { push } = useRouter();

  const [keyword, setKeyword] = useState(q);
  const [location, setLocation] = useState(l ? formatLocationCaseText(l) : '');
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [keywordSuggestions, setKeywordSuggestions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(dropdownOptions ? dropdownOptions[0] : null);

  const options = useMemo(() => {
    if (!dropdownOptions || dropdownOptions.length === 0) return [];
    return dropdownOptions.map((el) => {
      return {
        ...el,
        type: 'button',
        onClick: (el: any) => {
          setSelectedOption(el);
        },
        isActive: selectedOption?.title === el.title,
      };
    });
  }, [dropdownOptions, selectedOption]);

  const redirectFunction = ({ qValue, lValue }: { qValue?: string; lValue?: string }) => {
    if (
      !!qValue ||
      (!qValue && q) ||
      !!lValue ||
      (!lValue && l) ||
      fromHomePage ||
      selectedOption
    ) {
      push(
        `${selectedOption ? selectedOption.link : baseUrl}${makeQueryString({
          q: qValue,
          l: lValue?.toLowerCase().replaceAll(',', '').replaceAll(' ', '-'),
          ...(queryParameters ? { ...queryParameters } : null),
        })}`,
      );
    } else if (scrollElementId) {
      const targetElement = document ? document?.querySelector(scrollElementId) : null;
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    redirectFunction({ qValue: keyword, lValue: location });
  };

  useMount(() => {
    (async () => {
      if (q) {
        const resKeywords = await jobSearcherSuggestHandler({
          type: 'keyword',
          searchValue: q,
        });
        setKeywordSuggestions(resKeywords.suggestions);
      }
      if (l) {
        const resLocations = await jobSearcherSuggestHandler({
          type: 'location',
          searchValue: l,
        });
        setLocationSuggestions(resLocations.suggestions);
      }
    })();
  });

  const [isKeywordsSuggestionsOpened, setIsKeywordsSuggestionsOpened] = useState(!keyword);
  const [isLocationsSuggestionsOpened, setIsLocationsSuggestionsOpened] = useState(false);

  return (
    <>
      <IconsSprites />
      <section>
        {headerText && (
          <>
            {fromHomePage ? (
              <h2
                className={cn(
                  'text-2xl text-center text-main-text-color',
                  formClassName?.listTitle,
                )}
              >
                {headerText}
              </h2>
            ) : (
              <h1
                className={cn(
                  'text-2xl text-center text-main-text-color',
                  formClassName?.listTitle,
                )}
              >
                {headerText}
              </h1>
            )}
          </>
        )}
        <form
          className={cn(
            'flex flex-col gap-2 px-0 pb-10 lg:flex-row lg:justify-center lg:items-center',
            formClassName?.main,
          )}
          onSubmit={onSubmit}
          onKeyDown={(e) => {
            if (e.key == 'Enter') {
              if (
                (isLocationsSuggestionsOpened && locationSuggestions.length > 0) ||
                (isKeywordsSuggestionsOpened && keywordSuggestions.length > 0)
              ) {
                e.preventDefault();
              } else {
                onSubmit(e);
              }
            }
          }}
        >
          {dropdownOptions && selectedOption && dropdownClassNames && (
            <SearchDropdown
              options={options}
              currentOption={selectedOption?.title}
              classNames={dropdownClassNames}
            />
          )}
          <SearchField
            key={'q-input'}
            icon="#lens"
            name="q"
            id="q"
            placeholder={placeholders.keyword}
            maxLength={200}
            minLength={1}
            value={keyword}
            onChange={async (e: React.FormEvent<Element>) => {
              const target = e?.target as HTMLInputElement;
              setKeyword(target?.value);
              const resKeywords = await jobSearcherSuggestHandler({
                type: 'keyword',
                searchValue: target?.value,
              });

              setKeywordSuggestions(resKeywords.suggestions);
            }}
            cancelSearch={() => {
              setKeyword('');
              setKeywordSuggestions([]);
              redirectFunction({
                qValue: '',
                lValue: location,
              });
            }}
            suggestions={keywordSuggestions}
            onSuggestionsClick={(text) => {
              setKeyword(text);
              redirectFunction({ qValue: text, lValue: location });
            }}
            isSuggestionsOpened={isKeywordsSuggestionsOpened}
            setIsSuggestionsOpened={setIsKeywordsSuggestionsOpened}
            classNames={searchFieldClassName}
            autoFocus={!keyword}
          />
          <SearchField
            key={'l-input'}
            icon="#location"
            name="l"
            id="l"
            placeholder={placeholders.location}
            maxLength={100}
            minLength={1}
            value={location}
            onChange={async (e: React.FormEvent<Element>) => {
              const target = e?.target as HTMLInputElement;
              setLocation(target?.value);
              const resLocations = await jobSearcherSuggestHandler({
                type: 'location',
                searchValue: target?.value,
              });
              setLocationSuggestions(resLocations.suggestions);
            }}
            cancelSearch={() => {
              setLocation('');
              setLocationSuggestions([]);
              redirectFunction({
                qValue: keyword,
                lValue: '',
              });
            }}
            suggestions={locationSuggestions}
            onSuggestionsClick={(text) => {
              setLocation(text);
              redirectFunction({ qValue: keyword, lValue: text });
            }}
            isSuggestionsOpened={isLocationsSuggestionsOpened}
            setIsSuggestionsOpened={setIsLocationsSuggestionsOpened}
            classNames={searchFieldClassName}
          />
          <button
            className={cn(
              'flex items-center justify-center h-10 cursor-pointer border-none py-2 px-[14px] bg-primary',
              'uppercase text-center default-transition hover:bg-black',
              'default-disabled',
              formClassName?.button,
            )}
            type="submit"
          >
            {buttonText}
            {formClassName?.btnArrow ? <span className={formClassName.btnArrow}></span> : null}
          </button>
        </form>
      </section>
    </>
  );
};

export default SearchForm;
